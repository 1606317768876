import "react-day-picker/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";

import { isProduction } from "configs/general";
import { GlobalStyles } from "styles/GlobalStyles";
import { disableConsole } from "helpers/console";

import App from "./app";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import theme from "./theme";
import "./configs/i18n";
import { NotificationsBg } from "app/components/PantoNotify/PantoNotify.utils";

const root = document.getElementById("panto-root");
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Toaster
          icon={null}
          position="bottom-right"
          containerStyle={{
            display: "grid",
          }}
          toastOptions={{
            style: {
              color: "#fff",
              background: "#202124",
              fontSize: 13,
            },
            success: {
              icon: null,
              duration: 3000,
              style: {
                background: NotificationsBg.success,
              },
            },
            error: {
              icon: null,
              style: {
                background: NotificationsBg.error,
              },
            },
          }}
        />
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <GlobalStyles />
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </Provider>
);

if (isProduction) {
  disableConsole();
}

render(app, root);
serviceWorker.register();
